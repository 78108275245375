@mixin respond-to($media) {
  @if $media == handheld {
	@media only screen and (min-width: 0px) and (max-width: 767px) {
	  @content;
	}
  } @else if $media == tablet {
	@media only screen and (min-width: 768px) and (max-width: 1199px) {
	  @content;
	}
  } @else if $media == desktop {
	@media only screen and (min-width: 1200px) {
	  @content;
	}
  }
}


@mixin respondto($media) {
  @if $media == res1280 {
	@media only screen and (min-width: 0px) and (max-width: 1280px) {
	  @content;
	}
  } @else if $media == res990 {
	@media only screen and (min-width: 0px) and (max-width: 990px) {
	  @content;
	}
  } @else if $media == res850 {
	@media only screen and (min-width: 0px) and (max-width: 850px) {
	  @content;
	}
  } @else if $media == res600 {
	@media only screen and (min-width: 0px) and (max-width: 600px) {
	  @content;
	}
  }@else if $media == res768 {
	@media only screen and (min-width: 0px) and (max-width: 768px) {
	  @content;
	}
  } @else if $media == resDesktop {
	@media only screen and (min-width: 768px) {

	  @content;
	}
  }@else if $media == above1280 {
	 @media only screen and (min-width: 1281px) {
		@content;
	 }
  }@else if $media == below1500 {
	 @media only screen and (min-width: 0px) and (max-width: 1500px) {
		@content;
	 }
  }
}