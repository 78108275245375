.choices {
   position: relative;
   //margin-bottom: 24px;
   font-size: 16px
}

.choices:focus {
   outline: 0
}

.choices:last-child {
   margin-bottom: 0
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
   background-color: #EAEAEA;
   cursor: not-allowed;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none
}

.choices.is-disabled .choices__item {
   cursor: not-allowed
}

.choices [hidden] {
   display: none !important
}

.choices[data-type*=select-one] {
   cursor: pointer
}

.choices[data-type*=select-one] .choices__inner {
   padding-bottom: 7.5px
}

.choices[data-type*=select-one] .choices__input {
   display: block;
   width: 100%;
   padding: 10px;
   border-bottom: 1px solid #DDD;
   background-color: #FFF;
   margin: 0
}

.choices[data-type*=select-one] .choices__button {
   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
   padding: 0;
   background-size: 8px;
   position: absolute;
   top: 50%;
   right: 0;
   margin-top: -10px;
   margin-right: 25px;
   height: 20px;
   width: 20px;
   border-radius: 10em;
   opacity: .5
}

.choices[data-type*=select-one] .choices__button:focus, .choices[data-type*=select-one] .choices__button:hover {
   opacity: 1
}

.choices[data-type*=select-one] .choices__button:focus {
   box-shadow: 0 0 0 2px #00BCD4
}

.choices[data-type*=select-one] .choices__item[data-value=''] .choices__button {
   display: none
}

.choices[data-type*=select-one]:after {
   content: '';
   height: 0;
   width: 0;
   border-style: solid;
   border-color: #FFF transparent transparent;
   border-width: 5px;
   position: absolute;
   right: 11.5px;
   top: 50%;
   margin-top: -2.5px;
   pointer-events: none
}

.choices[data-type*=select-one].is-open:after {
   border-color: transparent transparent #FFF;
   margin-top: -7.5px
}

.choices[data-type*=select-one][dir=rtl]:after {
   left: 11.5px;
   right: auto
}

.choices[data-type*=select-one][dir=rtl] .choices__button {
   right: auto;
   left: 0;
   margin-left: 25px;
   margin-right: 0
}

.choices[data-type*=select-multiple] .choices__inner, .choices[data-type*=text] .choices__inner {
   cursor: text
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
   position: relative;
   display: inline-block;
   margin: 0 -4px 0 8px;
   padding-left: 16px;
   border-left: 1px solid #008FA1;
   background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
   background-size: 8px;
   width: 8px;
   line-height: 1;
   opacity: .75;
   border-radius: 0
}

.choices[data-type*=select-multiple] .choices__button:focus, .choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=text] .choices__button:focus, .choices[data-type*=text] .choices__button:hover {
   opacity: 1
}

.choices__inner {
   display: inline-block;
   vertical-align: top;
   width: 100%;
   background-color: #F9F9F9;
   padding: 7.5px 7.5px 3.75px;
   border: 1px solid #DDD;
   border-radius: 2.5px;
   font-size: 14px;
   min-height: 44px;
   overflow: hidden
}

.is-focused .choices__inner, .is-open .choices__inner {
   //border-color: #B7B7B7
}

.is-open .choices__inner {
   border-radius: 2.5px 2.5px 0 0
}

.is-flipped.is-open .choices__inner {
   border-radius: 0 0 2.5px 2.5px
}

.choices__list {
   margin: 0;
   padding-left: 0;
   list-style: none
}

.choices__list--single {
   display: inline-block;
   padding: 4px 16px 4px 4px;
   width: 100%
}

[dir=rtl] .choices__list--single {
   padding-right: 4px;
   padding-left: 16px
}

.choices__list--single .choices__item {
   width: 100%
}

.choices__list--multiple {
   display: inline
}

.choices__list--multiple .choices__item {
   display: inline-block;
   vertical-align: middle;
   border-radius: 20px;
   padding: 4px 10px;
   font-size: 12px;
   font-weight: 500;
   margin-right: 3.75px;
   margin-bottom: 3.75px;
   background-color: #00BCD4;
   border: 1px solid #00A5BB;
   color: #FFF;
   word-break: break-all;
   box-sizing: border-box
}

.choices__list--multiple .choices__item[data-deletable] {
   padding-right: 5px
}

[dir=rtl] .choices__list--multiple .choices__item {
   margin-right: 0;
   margin-left: 3.75px
}

.choices__list--multiple .choices__item.is-highlighted {
   background-color: #00A5BB;
   border: 1px solid #008FA1
}

.is-disabled .choices__list--multiple .choices__item {
   background-color: #AAA;
   border: 1px solid #919191
}

.choices__list--dropdown {
   visibility: hidden;
   z-index: 1;
   position: absolute;
   width: 100%;
   background-color: #FFF;
   //border: 1px solid #DDD;
   top: 100%;
   margin-top: -1px;
   border-bottom-left-radius: 2.5px;
   border-bottom-right-radius: 2.5px;
   overflow: hidden;
   //word-break: break-all;
   line-height: 2rem;
   will-change: visibility
}

.choices__list--dropdown.is-active {
   visibility: visible
}

.is-open .choices__list--dropdown {
   border-color: #B7B7B7
}

.is-flipped .choices__list--dropdown {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: -1px;
   border-radius: .25rem .25rem 0 0
}

.choices__list--dropdown .choices__list {
   position: relative;
   max-height: 300px;
   overflow: auto;
   -webkit-overflow-scrolling: touch;
   will-change: scroll-position
}

.choices__list--dropdown .choices__item {
   position: relative;
   padding: 10px;
   font-size: 14px
}

[dir=rtl] .choices__list--dropdown .choices__item {
   text-align: right
}

@media (min-width: 640px) {
   .choices__list--dropdown .choices__item--selectable {
	  //padding-right: 100px
   }
   .choices__list--dropdown .choices__item--selectable:after {
	  content: attr(data-select-text);
	  font-size: 12px;
	  opacity: 0;
	  position: absolute;
	  right: 10px;
	  top: 50%;
	  transform: translateY(-50%)
   }
   [dir=rtl] .choices__list--dropdown .choices__item--selectable {
	  text-align: right;
	  padding-left: 100px;
	  padding-right: 10px
   }
   [dir=rtl] .choices__list--dropdown .choices__item--selectable:after {
	  right: auto;
	  left: 10px
   }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
   background-color: #F2F2F2
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
   opacity: .5
}

.choices__item {
   cursor: default
}

.choices__item--selectable {
   cursor: pointer;
   font-size: 1.8rem;
   font-weight: 400;
}

.choices__item--disabled {
   cursor: not-allowed;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   opacity: .5
}

.choices__heading {
   font-weight: 600;
   font-size: 12px;
   padding: 10px;
   border-bottom: 1px solid #F7F7F7;
   color: gray
}

.choices__button {
   text-indent: -9999px;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border: 0;
   background-color: transparent;
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer
}

.choices__button:focus, .choices__input:focus {
   outline: 0
}

.choices__input {
   display: inline-block;
   vertical-align: baseline;
   background-color: #F9F9F9;
   font-size: 14px;
   margin-bottom: 5px;
   border: 0;
   border-radius: 0;
   max-width: 100%;
   padding: 4px 0 4px 2px
}

[dir=rtl] .choices__input {
   padding-right: 2px;
   padding-left: 0
}

.choices__placeholder {
   //opacity: .5
}

.custom__multiselect {

   &::after {
	  content: "";
	  height: 0;
	  width: 0;
	  border-style: solid;
	  border-color: #FFF transparent transparent;
	  border-width: 5px;
	  position: absolute;
	  right: 11.5px;
	  top: 50%;
	  margin-top: -2.5px;
	  pointer-events: none;

   }

   position: relative;
   width: 100%;

   & > span {
	  position: relative;
	  display: block;
	  padding: 11.5px;
	  padding-right: 27.5px;
	  border: 1px solid #DDD;
	  border-radius: 2.5px;
	  font-size: 1.8rem;
	  min-height: 45px;
	  font-weight: 400;
	  text-transform: none;
	  cursor: pointer;

	  .fas {
		 position: absolute;
		 right: 15px;
		 top: 13px;
	  }

   }

   &:hover {
	  .custom__multiselect__submenu {
		 display: block;
	  }
   }


   .custom__multiselect__submenu {
	  display: none;
	  width: 100%;
	  //border:1px solid red;
	  position: absolute;
	  top: 44px;
	  background: #FFF;
	  color: #333;
	  padding: 1rem 1rem;
	  border-radius: 3px;
	  //margin-top:5px;
	  z-index: 9;

	  span {
		 position: relative;
		 display: inline-block;
		 font-size: 1.2rem;
		 border-radius: 0.5rem;
		 padding: 0.5rem 0.5rem;
		 margin-bottom: 0.5rem;
		 cursor: pointer;
		 border: 1px solid transparent;


		 .x {
			display: none;
			position: absolute;
			right: -4px;
			top: -6px;
			background: red;
			color: #FFF;
			font-size: 1.0rem;
			//padding: 0.2rem;
			border-radius: 50%;
			width: 13px;
			height: 13px;
			text-align: center;

			line-height: 1.4rem;
			font-weight: 400;
			font-style: normal;
		 }

		 &:hover {
			border: 1px solid #444;
		 }

		 &.selected {
			border: 1px solid #000;

			.x {
			   display: block;
			}
		 }
	  }
   }
}