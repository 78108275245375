.event {
   .event__hero {
	  display: flex;
	  position: relative;
	  width: 100%;
	  min-height: 800px;
	  //height: 800px;

	  background-size: cover;
	  background-position: right top;
	  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.45);
	  background-image: url(/assets/images/pexels-photo-185480.jpeg);
	  text-transform: uppercase;

	  @include respondto('res1280') {
		 flex-direction: column;
		 min-height: unset;
	  }

	  .event__hero__side {
		 display: flex;
		 justify-content: center;
		 align-items: center;
		 flex-direction: column;
		 flex: 0 0 auto;

		 //position: absolute;
		 //right: 0;
		 //top: 0;
		 width: auto;
		 //height: 100%;
		 background-color: rgba(0, 84, 166, 0.5);
		 padding: 11.5rem 10rem;


		 & > div {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			padding: 2rem 0;

			@include respondto('res1280') {
			   padding: 0;
			   padding-right: 7rem;
			   align-items: flex-start;
			   text-align: left;
			}

			@include respondto('res600') {
			   padding-right: 4rem;
			}
		 }

		 @include respondto('res1280') {
			padding: 5rem;
			flex-direction: row;
			justify-content: flex-start;
		 }

		 @include respondto('res768') {
			padding: 5rem 8%;
		 }

		 span {
			color: #FEDD77;
			font-size: 5.0rem;
			font-weight: 400;

			@include respondto('below1500') {
			   font-size: 3rem;
			}

			@include respondto('res768') {
			   font-size: 1.9rem;
			}

			@include respondto('res600') {
			   font-size: 1.6rem;
			}
		 }

		 strong {
			color: #FFF;
			font-size: 11.0rem;
			font-weight: 600;

			@include respondto('below1500') {
			   font-size: 9rem;
			}

			@include respondto('res1280') {
			   text-indent: -0.4rem;
			}

			@include respondto('res768') {
			   font-size: 6rem;
			   text-indent: -0.3rem;
			}

			@include respondto('res600') {
			   font-size: 5rem;
			   text-indent: -0.2rem;
			}

			&:first-of-type {
			   //margin-bottom: 45px;
			}
		 }

		 span,
		 strong {
			margin: 0rem 0;
		 }
	  }

	  .event__description {
		 display: flex;
		 flex-direction: column;
		 //position: absolute;
		 //bottom: 65px;
		 //left: 50px;
		 padding: 6.5rem 5rem;
		 padding-top: 11.5rem;
		 justify-content: flex-end;
		 flex: 1;


		 @include respondto('res1280') {
			padding-top: 28rem;
		 }

		 @include respondto('res768') {
			padding-left: 8%;
			padding-right: 8%;
			padding-bottom: 5rem;
			//padding-top: 28rem;
		 }


		 .event__description__details {
			margin-top: 2.0rem;
			display: flex;
			align-items: stretch;

			@include respondto('res768') {
			   //flex-direction: column;
			   flex-wrap: wrap;
			}


			.event__texts {
			   display: inline-block;
			   vertical-align: top;
			   font-size: 1.8rem;
			   margin-left: 2.5rem;
			   //padding-right: 2rem;

			   @include respondto('res768') {
				  margin-left: 0;
				  //margin-top: 2.5rem;
			   }

			   h3 {
				  color: #FFF;
				  font-weight: 300;
				  margin-bottom: 1.0rem;

				  @include respondto('below1500') {
					 font-size: 1.7rem;
				  }
			   }

			   strong {
				  display: block;
				  color: #FEC135;
				  font-size: 2.5rem;
				  font-weight: 600;

				  @include respondto('below1500') {
					 font-size: 2rem;
				  }

				  &.alt {
					 font-size: 2.1rem;
					 color: #FFF;

					 @include respondto('below1500') {
						font-size: 1.8rem;
					 }
				  }
			   }

			   .event__texts__tags {
				  display: inline-block;
				  font-size: 1.5rem;
				  margin-top: 1.5rem;
				  color: #FFF;
				  margin-right: 5.0rem;

				  &.tags--yellow {
					 .tag {
						background-color: #FEC135;
						cursor: pointer;

					 }
				  }

				  &.tags--transparent {
					 .tag {
						background-color: transparent;
						border: 2px solid #FEC135;
						color: #FEC135;


					 }
				  }


			   }
			}

			.event__day {
			   display: flex;
			   flex-direction: column;
			   //align-items: center;
			   justify-content: center;
			   background: #FFF;
			   color: #000;
			   border-radius: 10px;
			   padding: 1.9rem 2.5rem;
			   text-align: center;

			   font-weight: 400;
			   //padding-top: 1.9rem;

			   @include respondto('below1500') {
				  font-size: 8rem;
			   }

			   @include respondto('res768') {
				  margin-bottom: 2rem;
			   }


			   span,
			   strong {
				  display: block;
			   }

			   span {
				  font-size: 2.0rem;
				  font-weight: 500;

				  @include respondto('below1500') {
					 font-size: 1.5rem;
				  }
			   }

			   strong {
				  font-size: 6.0rem;
				  font-weight: 500;

				  @include respondto('below1500') {
					 font-size: 4rem;
				  }
			   }
			}
		 }

		 h1,
		 h2 {
			text-transform: uppercase;
			font-weight: 500;
		 }

		 h1 {
			color: #FFF;
			font-size: 11rem;
			line-height: 10rem;

			@include respondto('below1500') {
			   font-size: 8rem;
			   line-height: 8.5rem;
			}

			@include respondto('res768') {
			   font-size: 5.5rem;
			   line-height: 6rem;
			}
		 }

		 h2 {
			color: #FEC135;
			font-weight: 300;
			font-size: 4.0rem;

			@include respondto('below1500') {
			   font-size: 2.7rem;
			}

			@include respondto('res768') {
			   font-size: 1.9rem;
			}
		 }

		 a.btn {
			text-transform: uppercase;
			font-size: 2.5rem;
			margin-top: 1.5rem;
		 }
	  }
   }
}


.form {

   .button_filter {
	  display: none;
	  background: #FEC135;
	  //text-align: center;
	  text-align: left;
	  //width: 100%;
	  border: 0;
	  border-radius: 4px;
	  padding: 13px 40px;
	  text-transform: uppercase;
	  letter-spacing: 0.1rem;
	  font-size: 1.8rem;
	  cursor: pointer;
	  transition: 0.3s ease;

	  img {
		 max-width: 14px;
	  }

	  &:hover {
		 filter: brightness(1.1);
	  }

	  @include respondto('res1280') {
		 display: block;
	  }


   }

}

.local {
   .event__day {
	  @include respondto('res768') {
		 display: none !important;
	  }
   }
}


.event__texts__tags {
   display: inline-block;
   font-size: 1.5rem;
   margin-top: 1.5rem;
   color: #FFF;
   margin-right: 5.0rem;

   &.tags--yellow {
	  .tag {
		 background-color: #FEC135;
	  }
   }

   &.tags--transparent {
	  .tag {
		 background-color: transparent;
		 border: 2px solid #FEC135;
		 color: #FEC135;
	  }
   }

   .tag {
	  display: inline-block;
	  padding: 0.4rem 1rem;
	  background-color: #FFF;
	  border-radius: 0.4rem;
	  color: #000;
	  margin-top: 0.5rem;
	  font-weight: 500;
	  cursor: pointer;


	  &:hover {
		 filter: brightness(1.1);
	  }

	  &.white {
		 &:hover {
			filter: brightness(1);
			opacity: 0.8;
		 }
	  }
   }
}