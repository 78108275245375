@import "../helpers/mixins.responsive";

.superwrapper {
  position: relative;
  //display:flex;
  //max-width: 1903px;
  max-width:100%;
  margin: 0 auto;

  background: #090909;
  overflow:hidden;

  @include respondto('res768') {
	max-width: 100%;
  }
}

@include respondto('res768') {
  .hidden-formobile {
	display: none !important;
  }
}