.events {
   .events__hero {
	  position: relative;
	  width: 100%;
	  height: 1170px;
	  height: auto;

	  @include respondto('res768') {
		 height: auto;
		 padding-top: 0;
		 padding-left: 8%;
		 padding-right: 8%;
	  }

	  background-size: cover;
	  background-position: right top;
	  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.65);
	  background-color: #00172B;
	  background-image: url(/assets/images/AdobeStock_249040256.jpg);
	  background-repeat: no-repeat;
	  text-transform: uppercase; /**/

	  padding: 5.0rem;

	  .events__hero__title {
		 color: #FFF;

		 //margin-top: 485px;
		 margin-top: 51vh;

		 @include respondto('res768') {
			margin-top: 0;
			padding-top: 28rem;
		 }

		 h1 {
			font-size: 10rem;
			font-weight: normal;
			line-height: 9rem;
			margin-bottom: 1rem;

			@include respondto('res768') {
			   font-size: 6rem;
			   line-height: 5rem;
			}

			@include respondto('res600') {
			   font-size: 4.5rem;
			   line-height: 4.8rem;
			}
		 }

		 h2 {
			font-size: 3.4rem;
			color: #FEC135;
			//font-weight: normal;
			font-weight: 300;

			@include respondto('res600') {
			   font-size: 2.5rem;
			}
		 }

		 span {
			font-size: 2.3rem;
			text-transform: none;
			line-height: 1.5;

			@include respondto('res768') {
			   font-size: 1.8rem;
			}
		 }
	  }

	  .event__description {
		 position: absolute;
		 bottom: 85px;
		 left: 85px;

		 .event__description__details {
			margin-top: 2.0rem;

			.event__texts {
			   display: inline-block;
			   vertical-align: top;
			   font-size: 1.8rem;
			   margin-left: 2rem;

			   h3 {
				  color: #FFF;
				  font-weight: 300;
				  margin-bottom: 1.0rem;
			   }

			   strong {
				  display: block;
				  color: #FEC135;
				  font-size: 2.5rem;
				  font-weight: 600;

				  &.alt {
					 font-size: 2.1rem;
					 color: #FFF;
				  }
			   }


			}

			.event__day {
			   display: inline-block;
			   vertical-align: top;

			   background: #FFF;
			   color: #000;
			   border-radius: 10px;
			   padding: 1.0rem 2.5rem;
			   text-align: center;

			   font-weight: 400;

			   span,
			   strong {
				  display: block;
			   }

			   span {
				  font-size: 2.0rem;
			   }

			   strong {
				  font-size: 6.0rem;
				  font-weight: 800;
			   }
			}
		 }

		 h1,
		 h2 {
			text-transform: uppercase;
			font-weight: 500;
		 }

		 h1 {
			color: #FFF;
			font-size: 11rem;
		 }

		 h2 {
			color: #FEC135;
			font-weight: 300;
			font-size: 4.0rem;
		 }

		 a.btn {
			text-transform: uppercase;
			font-size: 2.5rem;
			margin-top: 1.5rem;

		 }
	  }
   }
}

.tabsxxx {
   background: #001326;
   display: flex;
   align-items: center;

   .tab {
	  text-align: center;
	  flex: 1;
	  color: #FFF;
	  text-transform: uppercase;
	  letter-spacing: 2px;
	  padding: 2rem 3rem;
	  cursor: pointer;
	  font-size: 1.7rem;
	  font-weight: 600;

	  &:hover,
	  &[data-current='1'] {
		 background-color: #0054A6;
	  }
   }
}


.event__share {
   background: #001326;
   background-image: url(/assets/images/AdobeStock_249040256.jpg);
   padding: 8.0rem;
   text-align: center;
   box-shadow: inset 0 0 0 2000px rgba(0, 19, 38, 0.85);
   background-position: center center;

   @include respondto('res768') {
	  padding: 4rem 8%;
   }


   h3 {
	  color: #FEC135;
	  text-transform: uppercase;
	  margin-bottom: 2.0rem;
	  font-weight: 400;
	  margin-bottom: 30px;
   }

   .event__share__socials {
	  display: flex;
	  justify-content: center;

	  @include respondto('res768') {
		 display: block;
	  }

	  .icon {
		 position: relative;
		 color: #FFF;
		 font-size: 4rem;
		 margin-right: 3rem;
		 margin-left: 3rem;
		 transition: color 0.25s ease;
		 cursor: pointer;
		 text-decoration: none;

		 i {
			position: absolute;
			visibility: hidden;
			top: 0;
			left: 0;
		 }

		 @include respondto('res768') {
			display: inline-block;
			margin-right: 2rem;
			margin-left: 2rem;
			margin-bottom: 3rem;
		 }

		 &:last-of-type {
			//margin-right: 0;
		 }

		 &.icon-facebook:hover {
			color: #3B5998;
		 }

		 &.icon-twitter:hover {
			color: #1DA1F2;
		 }

		 &.icon-instagram:hover {
			color: #E1306C;
		 }

		 &.icon-whatsapp:hover {
			color: #25D366;
		 }

		 &.icon-telegram:hover {
			color: #08C;
		 }

		 &.icon-linkedin:hover {
			color: #0077B5;
		 }

		 &.icon-pinterest:hover {
			color: #BD081C;
		 }
	  }

	  div {
		 width: 90px;
		 height: 90px;
		 background-color: #FFF;

		 background-size: cover;
		 background-position: center center;
		 background-repeat: no-repeat;

		 cursor: pointer;

		 &:nth-child(1) {
			background-image: url(./assets/images/socials/facebook.png);
		 }

		 &:nth-child(2) {
			background-image: url(/assets/images/socials/twitter.png);
		 }

		 &:nth-child(3) {
			background-image: url(/assets/images/socials/instagram.png);
		 }

		 &:nth-child(4) {
			background-image: url(/assets/images/socials/whatsapp.png);
		 }

		 &:nth-child(5) {
			background-image: url(/assets/images/socials/telegram.png);
		 }

		 &:nth-child(5) {
			background-image: url(/assets/images/socials/telegram.png);
		 }

		 &:nth-child(6) {
			background-image: url(/assets/images/socials/linkedin.png);
		 }

		 &:nth-child(7) {
			background-image: url(/assets/images/socials/pinterest.png);
		 }

		 &:nth-child(8) {
			background-image: url(/assets/images/socials/telegram.png);
		 }
	  }
   }
}

.text__overlapped__text {
   font-size: 2rem;
   margin-top: 1rem;

   .tag {
	  position: relative;
	  display: inline-block;
	  font-size: 1.3rem;
	  border: 1px solid #FFF;
	  border-radius: 4px;
	  padding: 4px 10px;
	  margin-top: 1rem;
	  margin-right: 0.7rem;
   }

}

.text__overlapped {
   margin-top: 60px;

   color: #FFF;
   text-transform: none;


   h5 {
	  background-color: #FEC135;
	  color: #111;
	  display: inline-block;
	  padding: 1rem 3rem;
	  margin-bottom: 1rem;
	  font-size: 3.8rem;
	  font-weight: 400;
	  text-transform: uppercase;

	  @include respondto('res990') {
		 font-size: 2.4rem;
		 margin-bottom: 0;
	  }

	  @include respondto('res600') {
		 font-size: 2.2rem;
		 width: 100%;
		 padding: 0.8rem 1.8rem;
	  }
   }

   h4 {
	  color: #FEC135;
	  font-size: 4.2rem;
	  font-weight: 300;
	  letter-spacing: -0.1rem; /***/
	  text-transform: uppercase;

	  @include respondto('res600') {
		 font-size: 2.5rem;
	  }
   }

   &__container {

	  display: flex;
	  margin-top: 40px;
	  flex-wrap: wrap;

	  div {
		 @include respondto('res990') {
			flex: 0 0 100%;
			width: 100%;
		 }
	  }

	  div:first-of-type {
		 width: 40%;

		 background-image: url(/assets/images/img-box-contatti.jpg);
		 background-position: center center;
		 background-repeat: no-repeat;
		 background-size: cover;
		 height: 400px;

		 @include respondto('res990') {
			height: 250px;
		 }
	  }

	  div:last-of-type {
		 flex: 1;

		 margin-left: -12rem;
		 margin-top: 10rem;


		 @include respondto('res990') {
			margin-top: -2.4rem;
			margin-left: 0;
		 }

		 @include respondto('res600') {
			margin-top: 0;
		 }


		 p {
			background-color: #020000;
			box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.65);
			color: #FFF;
			font-size: 2rem;
			text-transform: none;
			line-height: 2.8rem;
			padding: 2.3rem;

			@include respondto('res990') {
			   padding: 0;
			   line-height: 2.3rem;
			   background-color: transparent;
			   box-shadow: none;
			   font-size: 1.7rem;
			   margin-bottom: 0;
			}
		 }
	  }
   }
}

.form {
   text-transform: uppercase;
   color: #FFF;
   margin-top: 140px;
   font-size: 2.0rem;

   @include respondto('res990') {
	  margin-top: 75px;
   }

   h4 {
	  color: #FEC135;
	  font-size: 4.2rem;
	  font-weight: 300;
	  letter-spacing: -0.1rem; /***/
	  text-transform: uppercase;
	  text-align: left;
	  display: inline-block;

	  &:not(:first-of-type) {
		 margin-top: 2rem;
	  }

	  @include respondto('res600') {
		 font-size: 2.5rem;
	  }
   }

   h5 {
	  font-size: 2.0rem;
	  font-weight: normal;
	  //margin-bottom: 4.0rem;
	  text-transform: none;
	  padding-bottom: 2.5rem;
	  text-align: left;
   }

   .input__row {
	  position: relative;
	  margin-bottom: 3.0rem;
	  display: flex;
	  flex-wrap: wrap;


	  &:not(.keep) {
		 @include respondto('res1280') {
			display: none;
		 }
	  }


	  .input {
		 vertical-align: top;
		 padding-right: 5rem;
		 flex: 0 0 auto;


		 &.input--w20 {
			width: 20%;
		 }

		 &.input--w25 {
			width: 25%;
		 }

		 &.input--w33 {
			width: 33%;
			flex: 0 0 auto;


		 }

		 &.input--w50 {
			width: 50%;
		 }

		 &.input--w66 {
			width: 66%;
		 }

		 &.input--w75 {
			width: 75%;
		 }

		 &.input--w100 {
			width: 100%;

			@include respondto('res768') {
			   padding-right: 0;
			}
		 }

		 &.input--w25 {
			@include respondto('res1280') {
			   &:nth-child(1),
			   &:nth-child(2) {
				  margin-bottom: 3rem;
			   }
			}
		 }

		 &.empty {

		 }

		 &.input--w25,
		 &.input--w50,
		 &.input--w33 {
			@include respondto('res1280') {
			   width: 50%;

			   &.plaintext {
				  width: 100%;
			   }

			   &.empty {
				  display: none;
			   }
			}

			@include respondto('res768') {
			   width: 100%;
			   margin-bottom: 3rem;
			   padding-right: 0;

			   &:last-of-type {
				  margin-bottom: 0;
			   }
			}
		 }

		 label {
			display: block;
			margin-bottom: 1rem;
			text-align: left;
		 }

		 input[type='text'],
		 input[type='date'],
		 select,
		 textarea {
			//width: 100%;
			background-color: transparent;
			border: 0;
			border-bottom: 1px solid #5F5F5F;
			color: #FFF;
			padding-top: 1rem;
			line-height: 2.7rem;
			padding-bottom: 0.5rem;
			max-width: 100%;
			display: block;
			width: 100%;
			cursor: text;
			min-height: 4.5rem;

			&[readonly] {
			   cursor: not-allowed;
			}
		 }


		 input[type='text'] {
			//padding-top: 1.3rem;
			//padding-bottom: 0.5rem;
		 }

		 select,
		 textarea {
			background-color: transparent;
			border: 1px solid #5F5F5F;
			border-radius: 5px;

		 }

		 textarea {
			padding: 1.5rem;
			width: 100%;
			height: 300px;
			resize: vertical;
		 }

		 div.tiny {
			font-size: 1.3rem;
			margin-top: 0.5rem;
			color: #E6E6E6;
			line-height: 1.4;
			text-transform: none;
		 }

		 span.tag {
			position: relative;
			display: inline-block;
			font-size: 1.3rem;
			border: 1px solid #FFF;
			border-radius: 4px;
			padding: 4px 10px;
			margin-top: 1rem;
			margin-right: 0.7rem;

			.x {
			   position: absolute;
			   right: -8px;
			   top: -6px;
			   width: 15px;
			   height: 15px;
			   font-size: 1.1rem;
			   display: inline-block;
			   text-align: center;
			   border-radius: 50%;
			   padding: 0.2rem;
			   color: #FFF;
			   background-color: #D20042;
			   font-style: normal;
			   line-height: 1.0rem;
			   cursor: pointer;
			}
		 }
	  }
   }
}

.coupon {
   display: inline-block;
   //width: 400px;
   flex: 1;
   padding-right: 6rem;
   margin-bottom: 8rem;

   @include respondto('res850') {
	  flex: 0 0 50%;
   }

   @include respondto('res600') {
	  flex: 0 0 100%;
	  padding-right: 0;

	  &:nth-child(1),
	  &:nth-child(2) {
		 margin-bottom: 5rem;
	  }
   }

   .coupon__mid {
	  text-align: center;
	  margin-top: 2rem;

	  h5 {
		 color: #FEC135;
		 font-size: 2.8rem;
		 padding-bottom: 0;
		 text-align: center;
	  }

	  p {
		 margin-top: 2rem;
		 text-transform: none;
		 padding: 0 2rem;
		 padding: 0 2rem;
		 line-height: 2.5rem;

		 @include respondto('res990') {
			font-size: 1.6rem;
			line-height: 2rem;
		 }
	  }
   }

   .coupon__top {
	  z-index: 0;
	  position: relative;
	  text-align: center;

	  .coupon__top__line1 {
		 width: 100%;
	  }

	  .coupon__top__line1,
	  .coupon__top__line2 {
		 width: 34%;
		 height: 3px;
		 background-color: #FFF;
		 position: absolute;
		 top: 12px;
		 z-index: 1;
	  }

	  .coupon__top__line1 {
		 width: 100%;
		 z-index: -1;
	  }

	  .coupon__top__line2 {
		 right: 0;
	  }

	  span {
		 display: inline-block;
		 background-color: #FFF;
		 color: #000;
		 padding: 0.3rem 3rem;
		 font-weight: 500;
		 font-size: 1.5rem;
		 z-index: 9;

	  }
   }

   .coupon__bottom {

	  position: relative;
	  text-align: center;
	  z-index: 0;


	  .coupon__bottom__line1,
	  .coupon__bottom__line2 {
		 //width: 45%;
		 height: 1px;
		 background-color: #FEC135;
		 position: absolute;
		 top: 20px;
		 //z-index: 1;
	  }

	  .coupon__bottom__line1 {
		 z-index: -1;
		 width: 100%;
	  }

	  .coupon__bottom__line2 {
		 right: 0;
	  }

	  .coupon__label {
		 display: inline-block;
		 text-transform: none;
		 width: 45px;
		 height: 45px;
		 border-radius: 50%;
		 background-color: #FEC135;
		 color: #000;
		 font-size: 1.4rem;
		 z-index: 9;
		 font-weight: 500;
		 line-height: 4.2rem;
		 letter-spacing: 0.1rem;
	  }
   }
}

.events__custom {
   .row__description {
	  display: flex;

	  div:first-of-type {
		 padding-right: 3rem;
	  }

	  div {
		 width: auto !important;
	  }

   }
}