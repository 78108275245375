.blog__article {
  background: #091421;

  padding: 20rem 5rem;
  padding-bottom: 7rem;
  //padding-bottom:0;

  color: #FFF;

  @include respondto('res768') {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @include respondto('res600') {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  & > h1 {
    font-size: 6rem;
    font-weight: 500;

    @include respondto('res768') {
      font-size: 4.5rem;
      line-height: 6rem;
    }

    @include respondto('res600') {
      font-size: 3.8rem;
      line-height: 4.8rem;
    }
  }

  .blog__article__tags {
    position: relative;
    top: auto;
    left: auto;
    margin-top: 2.5rem;
  }

  .blog__article__sub {
    margin-top: 2rem;
    margin-bottom: 6rem;
    letter-spacing: 0.2rem;
    font-size: 1.4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-weight: 500;
    }
  }

  .blog__article__text__container {
    display: flex;
  }

  .blog__article__text__main {
    flex: 1;
    padding-right: 300px;
    transition: transform 0.5s ease, opacity 0.5s ease;


    @include respondto('below1500') {
      padding-right: 15%;
    }

    @include respondto('res1280') {
      padding-right: 0;
      flex: 1 0 auto;
      width: 100%;
    }

    img {
      width: 100%;
    }

    p {
      line-height: 25px;
      text-align: justify;
      font-weight: 400;
    }

    blockquote {
      border-left: 3px solid #FEBF0F;
      color: #FEBF0F;
      line-height: 25px;
      padding: 0.6rem 0;
      padding-left: 1.5rem;
      text-align: justify;
      margin: 4rem 0;
      font-style: italic;
      font-weight: 500;
      font-size: 1.8rem;
    }
  }

  .blog__article__text__side {
    position: relative;
    width: 400px;


    .blog__article__text__side__wrapper {
      //border: 1px solid red;
      position: absolute;
      top: 0;
      right: 0;
      width: inherit;

      flex: 0 0 auto;
      transition: transform 0.5s ease;


      //height:200px;
      //overflow:hidden;

      &.fixed {
        //border: 3px solid cyan;
        position: fixed;
        right: 5rem;
        top: 6rem;
      }

      &.bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        top: unset;
      }


      @include respondto('res1280') {
        width: 350px;
        right: -450px;

        &.fixed {
          right: -400px;
        }

        &.bottom {
          right: -450px;
        }
      }

      // ciao

      @include respondto('res768') {
        width: 280px;
        right: -320px;

        &.fixed {
          right: -280px;
        }

        &.bottom {
          right: -320px;
        }
      }

      @include respondto('res600') {
        width: 240px;
        right: -272px;

        &.fixed {
          right: -240px;

        }

        &.bottom {
          right: -272px;
        }
      }
      ///////aaa


      b {
        display: block;
        color: #FEBF0F;
        text-transform: uppercase;
        font-weight: 500;
        //margin-bottom: 2rem;
        margin-top: 3rem;
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;

        @include respondto('res600') {
          &:first-of-type {
            //margin-top: 0;
          }
        }


      }


      input {
        border: 1px solid #87878D;
        padding: 1rem;
        width: 100%;
        background: transparent;
        color: #FFF;
        padding: 0.8rem 1rem;
        font-size: 1.4rem;
      }

      input::-webkit-input-placeholder,
      input::-moz-placeholder {
        color: #CACACE;
        font-style: italic;
      }
    }

    .blog__about__nightcode {
      @include respondto('res768') {
        display: none;
      }

      .blog__about__nightcode__text {
        font-size: 1.4rem;
        display: block;
      }

    }

    .blog__search {
      @include respondto('res600') {
        //display: none;
      }

      b {
        margin-top: 0;
      }
    }

    .recent__article {
      display: flex;
      margin-bottom: 2rem;

      .recent__article__image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: #FEBF0F;
        margin-right: 1.2rem;
      }

      .recent__article__content {
        flex: 1;

        .recent__article__content__title {
          display: block;
          font-weight: 500;
          font-size: 1.4rem;
          color: inherit;
          text-decoration: none;
        }

        span {
          color: #CACACE;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: 0.1rem;
          display: block;
          padding-top: 0.7rem;
        }
      }
    }

    .squared__images {
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 33.3%;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      & > div:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    .nightcode__logo {
      background: #FEBF0F;
      color: #091421;
      text-align: center;
      font-size: 3rem;
      font-weight: 100;
      padding: 1rem 2rem;
      margin-bottom: 1.3rem;
      display: inline-block;
    }

    & > span {
      display: block;
      line-height: 20px;
      text-align: justify;
      font-size: 1.3rem;
    }
  }

  .blog__article__text__container.open {


    @include respondto('res1280') {
      .blog__article__text__main {
        transform: translate3d(-400px, 0, 0);
        opacity: 0.2;
      }

      .blog__article__text__side__wrapper {
        transform: translate3d(-450px, 0, 0);
      }
    }

    @include respondto('res768') {

      .blog__article__text__main {
        transform: translate3d(-320px, 0, 0);
      }

      .blog__article__text__side__wrapper {
        transform: translate3d(-320px, 0, 0);
      }

    }

    @include respondto('res600') {

      .blog__article__text__main {
        transform: translate3d(-272px, 0, 0);
      }

      .blog__article__text__side__wrapper {
        transform: translate3d(-272px, 0, 0);
      }

    }


  }

  .article__author {
    position: relative;
    width: 355px;
    margin-top: 50px;
    border-radius: 10px;

    @include respondto('res600') {
      width: 100%;
    }

    .article__author__banner {
      height: 175px;
      border-radius: 10px 10px 0 0;
      background-image: url(/assets/images/temp/fake_article.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .article__author__avatar {
      position: absolute;
      width: 100px;
      height: 100px;

      left: 50%;
      transform: translate(-50%, 0);
      top: 125px;
      background: #FFF;
      background-image: url(https://c4.wallpaperflare.com/wallpaper/920/195/675/girl-face-makeup-actress-wallpaper-preview.jpg);
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .article__author__socials {
      width: 100%;
      position: absolute;
      bottom: -19px;
      left: 0;
      display: block;
      text-align: center;

      & > div {
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: brown;
        margin-right: 1.8rem;
        line-height: 39px;

        &.facebook {
          background: #4170B7;
        }

        &.twitter {
          background: #299BD6;
        }

        &.instagram {
          background: #912E8D;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .article__author__text {

      .article__author__name {
        margin-bottom: 1rem;
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.1rem;
        display: block;
      }

      span {
        font-size: 1.3rem;
      }

      background: #FFF;
      border-radius: 0 0 10px 10px;
      color: #222;
      text-align: center;
      padding: 4rem 2.5rem;
      padding-top: 6.7rem;
      //padding-bottom: 4rem;
    }
  }

}

.alt__tags {

  //margin-top: 4rem;
  margin-bottom: -1rem;

  .alt__tag {
    display: inline-block;
    background: #FFF;
    color: #222;
    border-radius: 3px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-right: 0.4rem;
    cursor: pointer;
    transition: background 0.2s ease;
    margin-bottom: 1rem;
    text-transform: uppercase;
    padding: 0.7rem 1.3rem;

    &:hover {
      background: #FEBF0F;
    }
  }

  &.blog {
    .alt__tag {
      font-size: 1rem;
      border-radius: 2px;
      margin-right: 0.2rem;
      font-weight: 600;
      margin-bottom: 0.7rem;
      padding: 0.5rem 1rem;
    }
  }
}

.blog__article__others {
  position: relative;
  margin-top: 7rem;

  .title {
    height: 30px;
  }

  b {
    text-transform: uppercase;
    background: #091421;
    z-index: 2;
    position: absolute;
    top: 6px;
    left: 20px;
    padding: 0 1rem;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;


    @include respondto('res600') {
      left: 0;
      top: 0;
      position: relative;
      padding: 0;
    }

  }

  .hr {
    position: absolute;
    top: 13px;
    left: 0;

    @include respondto('res600') {
      display: none;
    }

    width: 100%;
    height: 1px;
    background: #FFF;
    z-index: 1;
  }

  .blog__article__others__container {
    display: flex;
    margin-top: 2rem;

    @include respondto('res768') {
      flex-wrap: wrap;
    }

    .blog__article__other {
      position: relative;
      width: 33.33%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: url(/assets/images/adult-bar-birthday-787961.jpg);
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
      margin-right: 2rem;

      @include respondto('res768') {
        flex: 0 0 auto;
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
      }

      .dummy {
        display: block;
        padding-top: 93%;

        @include respondto('res768') {
          padding-top: 28%;
        }

        @include respondto('res600') {
          padding-top: 60%;
        }

      }

      &:last-of-type {
        margin-right: 0;
      }

      .content {

        position: absolute;
        width: 100%;
        height: 100%;
        padding: 2rem;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tags {

          .tag {
            display: inline-block;
            border: 2px solid #FEBF0F;
            padding: 0.2rem 0.6rem;
            border-radius: 4px;
            color: #FEBF0F;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background: #FEBF0F;
              color: #333;
            }
          }
        }

        .blog__article__other__title {
          font-size: 2.5rem;
          font-weight: 500;
          display: block;

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              color: #FEBF0F;
            }
          }
        }

      }
    }
  }


}

.blog__button {

  display: none;

  @include respondto('res1280') {
    display: inline-block;
  }


  button {
    opacity: 0;
    visibility: hidden;
    font-size: 1.3rem;
    font-weight: 600;
    position: fixed;
    bottom: 0;
    right: 5rem;
    z-index: 98;
    cursor: pointer;
    padding: 1rem 1.5rem;
    letter-spacing: 0.1rem;
    color: #000000;
    border: 0;
    background: #fec135;
    border-radius: 0.4rem 0.4rem 0 0;
    transition: filter 0.5s ease, background 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
    text-transform: uppercase;

    padding-left: 4rem;

    @include respondto('res768') {
      right: 4rem;
    }

    img {
      width: 17px;
      display: inline-block;
      position: absolute;
      left: 1.5rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &:hover {
      filter: brightness(1.1);
    }
  }

}


.blog__button2 {

  display: none;

  @include respondto('res1280') {
    display: inline-block;
  }


  button {

    position: relative;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    padding: 0.7rem 1.2rem;
    letter-spacing: 0.1rem;
    color: #000000;
    border: 0;
    background: #fec135;
    border-radius: 0.2rem;
    transition: filter 0.5s ease, background 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;
    text-transform: uppercase;
    padding-left: 3.6rem;

    @include respondto('res600') {
      display: none;
    }

    & > img {
      width: 15px;
      display: inline-block;
      position: absolute;
      left: 1.2rem;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &:hover {
      filter: brightness(1.1);
    }
  }

}