.row {
  position: relative;
  width: 100%;
  height: 800px;

  @include respondto('res1280') {
    height: auto;
  }


  //background: red;
  background-size: cover;
  background-position: right center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);


  .row__description {
    position: absolute;
    padding-right: 5rem;
    left: 50px;

    @include respondto('res768') {
      left: 0;
      padding: 0 4rem;
    }

    h1,
    h2 {
      text-transform: uppercase;
      font-weight: 400;
    }

    h1 {
      color: #FFF;
      font-size: 10rem;
      line-height: 9rem;

      @include respondto('res768') {
        font-size: 6rem;
        line-height: 5rem;
      }

      @include respondto('res600') {
        font-size: 4.5rem;
        line-height: 4.8rem;
      }
    }

    h2 {
      color: #FEC135;
      font-weight: 300;
      font-size: 3.4rem;
      margin-top: 3rem;

      @include respondto('res600') {
        font-size: 2.5rem;
        //line-height: 4.8rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      a {
        color: inherit;
        text-decoration: none;
        letter-spacing: -0.1rem;

        &:hover {
          //border-bottom: 1px solid #FEC135;
        }
      }
    }

    .w--margin {
      margin: 2rem 0;
    }

    .white__link {
      display: inline-block;
      margin-right: 0.8rem;
      background: #FFF;
      color: #000;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 2px;
      font-weight: 500;
      font-size: 1.4rem;
      transition: background-color 0.25s ease;
      margin-bottom: 1.1rem;

      &:hover {
        background-color: rgba(254, 193, 53, 1);
      }
    }

    a.btn {
      font-size: 1.4rem;
      letter-spacing: 0.1rem;
      font-weight: 400;
      transition: background-color 0.25s ease;
      padding: 0.8rem 1.8rem;
      margin-top: 1.2rem;
      opacity: 0.8;
      text-transform: uppercase;

      &:hover {
        background-color: rgba(254, 193, 53, 1);
        opacity: 1;
      }
    }
  }

  &.row__single {

    height: 100vh;

    h1 {
      margin-bottom: 1rem;
      cursor: pointer;
    }

    span {
      display: block;
      color: #FFF;
      font-size: 2.3rem;
      line-height: 1.5;

      @include respondto('res600') {
        font-size: 1.8rem;
      }
    }

    a.btn {
      margin-top: 1.5rem;
      font-size: 1.7rem;
      letter-spacing: 0.1rem;
      font-weight: 400;
    }

    &.row--image1 {
      background-image: url(/assets/images/47b895580bf60047640c6b309a3ce29ab217d089.jpg);
      background-position: center center;

      @include respondto('res768') {
        background-image: url(/assets/images/img-header-mobile-nc.jpg);
        height: auto;
      }
    }

    .row__description {
      bottom: 141px;


      @include respondto('res768') {
        bottom: unset;
        position: relative;
        padding: 0 8%;
        padding-top: 28rem;
        padding-bottom: 5rem;
      }

      @include respondto('res1280') {
        padding-right: 5rem;
      }

    }

    &.row--image2 {
      background-image: url(/assets/images/adult-beautiful-blonde-1667761.jpg);
    }

    &.row--image3 {
      height: 1160px;
      background-image: url(/assets/images/band-concert-dark-1699161.jpg);
    }
  }

  &.row--right--text {
    .row__description {
      left: auto;
      right: 50px;
      padding-left: 50px;
      text-align: right;
    }
  }

  &.row__penta {
    display: flex;

    @include respondto('res768') {
      display: block;
      flex-direction: column;
    }

    @include respondto('res1280') {
      display: block;
      flex-direction: column;
    }


    &.row__penta--detailed {
      .row__penta__big {


        .row__description {
          width: 100%;
          left: 0 !important;
          bottom: 0 !important;
          padding: 2.5rem;
          background-color: rgba(0, 19, 38, 0.5);
          justify-content: space-between;

          @include respondto('res768') {
            padding-right: 35px;
          }

          h1 {
            font-size: 5rem;
            letter-spacing: -1px;
            line-height: 5.6rem;
            margin-bottom: 0;

            @include respondto('res768') {
              font-size: 4.2rem;
              line-height: 3.6rem;
            }

          }

          h2 {
            letter-spacing: -1px;
            line-height: 3.6rem;
            margin-bottom: 0.5rem;

            @include respondto('res768') {
              font-size: 2.5rem;
              line-height: 2.6rem;
              margin-bottom: 0;
            }
          }

          h3 {
            color: #FFF;
            text-transform: uppercase;
            font-weight: 400;

            @include respondto('res768') {
              //font-size: 1.7rem;
            }
          }

          a.btn {
            //font-size: 1.5rem;

            font-size: 1.6rem;
            letter-spacing: 0.1rem;
            font-weight: 400;
            padding: 1.1rem 2.6rem;

            /*font-size: 1.4rem;
letter-spacing: 0.1rem;
font-weight: 400;
transition: background-color 0.25s ease;
padding: 0.8rem 1.8rem;
margin-top: 1.2rem;
opacity: 0.8;*/

            @include respondto('res768') {
              font-size: 1.4rem;
              padding: 0.8rem 1.8rem;
            }

            &:first-of-type {
              margin-right: 0.5rem;

            }
          }

          & > div {
            display: inline-block;

            &:first-of-type {
              width: 670px;
              vertical-align: top;
            }

            &:last-of-type {
              width: 140px;
              vertical-align: top;
              text-transform: uppercase;
              font-weight: 400;
              color: #FFF;

              span,
              strong {
                display: block;
              }

              span {
                color: #FEC135;
                font-size: 3rem;

                @include respondto('res768') {
                  font-size: 1.6rem;
                }

                &.smaller {
                  font-size: 2.2rem;
                }
              }

              strong {
                font-size: 5rem;
                margin-bottom: 1.0rem;
                font-weight: 600;
                line-height: 5rem;

                @include respondto('res768') {
                  font-size: 3.6rem;
                  line-height: 3.6rem;
                }

                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .row__penta__others {
        .row__description {
          width: 100%;
          left: 0 !important;
          bottom: 0 !important;
          padding: 2.5rem;
          background-color: rgba(0, 19, 38, 0.5);
          justify-content: space-between;

          h1 {
            font-size: 4rem;
            letter-spacing: -2px;
          }

          h2 {
            letter-spacing: -2px;
          }

          h3 {
            color: #FFF;
            text-transform: uppercase;
            font-weight: 400;
          }

          a.btn {
            font-size: 1.0rem;
          }

          & > div {
            display: inline-block;

            &:first-of-type {
              width: 310px;
              vertical-align: top;
            }

            &:last-of-type {
              width: 110px;
              vertical-align: top;
              text-transform: uppercase;
              font-weight: 400;
              color: #FFF;

              span,
              strong {
                display: block;
              }

              span {
                color: #FEC135;
                font-size: 1.7rem;
              }

              strong {
                font-size: 3.6rem;
                margin-bottom: 1.0rem;
                font-weight: 600;
                letter-spacing: -0.1rem;
                line-height: 3.6rem;

                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .row__penta__big {
      position: relative;
      width: 50%;
      height: 100%;

      @include respondto('res1280') {
        width: 100%;
        height: 500px;
      }


      background: url(/assets/images/pexels-photo-2901918.jpeg);
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      transition: box-shadow .15s ease;

      &[onclick] {
        cursor: pointer;
      }

      @include respondto('res768') {
        display: block;
        width: 100%;

        height: 450px;

      }

      &:hover {
        box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
      }

      .row__penta__others__el__coupon {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background-color: #FEC135;
        color: #000;
        width: 70px;
        height: 70px;
        text-align: center;
        border-radius: 50%;
        font-weight: 400;
        line-height: 7rem;
        letter-spacing: 0.2rem;
        font-size: 1.8rem;

        @include respondto('res768') {
          width: 50px;
          height: 50px;
          line-height: 5rem;
          font-size: 1.6rem;
          letter-spacing: 0.1rem;
        }

      }

      .row__description {

        left: 50px;
        bottom: 50px;
        padding-right: 50px;

        @include respondto('res1280') {
          left: 35px;
          padding-right: 35px;
          bottom: 35px;
        }

        h1 {
          font-size: 11.5rem;
          font-weight: 400;
          margin-bottom: 1.1rem;
          cursor: pointer;
          letter-spacing: -0.5rem;
        }

        h2 {
          font-size: 3.6rem;
        }

        a.btn {
          margin-top: 1.5rem;
          font-size: 1.7rem;
          letter-spacing: 0.1rem;
          font-weight: 400;
        }
      }
    }

    .row__penta__others {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      //background-color: green;


      @include respondto('res768') {
        display: block;
        width: 100%;
        flex-direction: column;
      }

      @include respondto('res1280') {
        display: flex;
        width: 100%;
      }

      .row__penta__others__el {
        position: relative;
        width: 50%;

        //background-color: yellow;
        //

        @include respondto('res768') {
          width: 100%;
          //height:300px;
          height: 450px;
        }

        @include respondto('res1280') {
          //font-size: 0;
          display: inline-block;
          height: 450px;
          //width: %;
        }

        &[onclick] {
          cursor: pointer;
        }


        .row__penta__others__el__coupon {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          background-color: #FEC135;
          color: #000;
          width: 50px;
          height: 50px;
          text-align: center;
          border-radius: 50%;
          font-weight: 400;
          line-height: 5rem;
          font-size: 1.6rem;
          letter-spacing: 0.1rem;

        }

        &:nth-child(1) {
          background-image: url(/assets/images/pexels-photo-2887883.jpeg);
        }

        &:nth-child(2) {
          background-image: url(/assets/images/pexels-photo-270968.jpeg);
        }

        &:nth-child(3) {
          background-image: url(/assets/images/pexels-photo-415212.jpeg);
        }

        &:nth-child(4) {
          background-image: url(/assets/images/pexels-photo-185480.jpeg);
        }

        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        transition: box-shadow .15s ease;

        &:hover {
          box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
        }

        .row__description {
          bottom: 25px;
          left: 25px;
          padding-right: 25px;

          @include respondto('res1280') {
            left: 35px;
            padding-right: 35px;
            bottom: 35px;
          }

          h1 {
            font-size: 4.2rem;
            font-weight: 400;
            line-height: 3.6rem;
            margin-bottom: 0rem;
            cursor: pointer;
            letter-spacing: -0.2rem;

            @include respondto('res768') {

            }
          }

          h2 {
            font-size: 2.5rem;
            letter-spacing: -1px;
          }

          a.btn {
            font-size: 1.4rem;
            letter-spacing: 0.1rem;
            font-weight: 400;

            transition: background-color .25s ease;
            padding: 0.8rem 1.8rem;
            margin-top: 1.2rem;
            opacity: 0.8;

            &:hover {
              background-color: #FEC135;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.row__penta2 {
    display: flex;
    height: auto;
    flex-wrap: wrap;

    .row__penta__el {
      flex: 0 0 auto;
      position: relative;
      width: 20%;
      //height: 100%;

      @include respondto('res1280') {
        width: 25%;
      }

      @include respondto('res990') {
        width: 33.33%;
      }

      @include respondto('res768') {
        width: 100%;
      }

      .dummy {
        padding-top: 80%;


        @include respondto('res768') {
          padding-top: 40%;
        }

        @include respondto('res600') {
          padding-top: 55%;
        }
      }

      &:nth-child(1) {
        background-image: url(/assets/images/pexels-photo-235441.jpeg);
      }

      &:nth-child(2) {
        background-image: url(/assets/images/pexels-photo-783243.jpeg);
      }

      &:nth-child(3) {
        background-image: url(/assets/images/pexels-photo-2889537.jpeg);
      }

      &:nth-child(4) {
        background-image: url(/assets/images/pexels-photo-1449791.jpeg);
      }

      &:nth-child(5) {
        background-image: url(/assets/images/pexels-photo-783243.jpeg);
      }

      &:nth-child(6) {
        background-image: url(/assets/images/pexels-photo-235441.jpeg);
      }

      &:nth-child(7) {
        background-image: url(/assets/images/pexels-photo-783243.jpeg);
      }

      &:nth-child(8) {
        background-image: url(/assets/images/pexels-photo-2889537.jpeg);
      }

      &:nth-child(9) {
        background-image: url(/assets/images/pexels-photo-1449791.jpeg);
      }

      &:nth-child(10) {
        background-image: url(/assets/images/pexels-photo-783243.jpeg);
      }

      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;


      .row__description {
        bottom: 25px;
        //left: 25px;
        //right: 15px;
        text-align: left;

        left: 0rem;
        padding: 0 2.5rem;
        width: 100%;

        @include respondto('res768') {
          left: 8%;
        }

        h1 {
          font-size: 3rem;
          font-weight: 400;
          line-height: 3.2rem;
          margin-bottom: 0rem;
          cursor: pointer;
          letter-spacing: -0.2rem;
        }

        h2 {
          font-size: 2rem;
          letter-spacing: -1px;
        }

        a.btn {
          font-size: 1.2rem;
          margin-top: 0.75rem;
        }
      }
    }
  }

  &.row__quad {
    display: flex;
    //background-color: fuchsia;
    height: 400px;

    @include respondto('res1280') {
      height: auto;
      //flex-direction: column;
      flex-wrap: wrap;
    }

    .row__quad__el {
      position: relative;
      width: 25%;
      height: 100%;

      @include respondto('res1280') {
        width: 50%;
        height: 450px;
      }

      &:nth-child(1) {
        background-image: url(/assets/images/pexels-photo-235441.jpeg);
      }

      &:nth-child(2) {
        background-image: url(/assets/images/pexels-photo-783243.jpeg);
      }

      &:nth-child(3) {
        background-image: url(/assets/images/pexels-photo-2889537.jpeg);
      }

      &:nth-child(4) {
        background-image: url(/assets/images/pexels-photo-1449791.jpeg);
      }

      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      transition: box-shadow .15s ease;

      &:hover {
        box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
      }

      h3 {
        position: absolute;
        left: 16px;
        top: 14px;
        color: #C2DDF0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
        border: 2px solid;
        padding: 0.7rem;
        border-radius: 0.3rem;
        opacity: 0.8;
      }

      .row__description {
        bottom: 25px;
        left: auto;
        right: 25px;
        text-align: right;
        padding-left: 25px;

        @include respondto('res1280') {
          right: 35px;
          padding-left: 35px;
          bottom: 35px;
        }

        h1 {
          font-size: 4.2rem;
          line-height: 3.6rem;
          font-weight: 400;
          letter-spacing: -0.2rem;
        }

        h2 {
          font-size: 2.6rem;
        }


        a.btn {
          font-size: 1.5rem;
          margin-top: 1.2rem;
          padding: 1rem 2rem;
          letter-spacing: 0.1rem;
          font-weight: 400;
        }
      }
    }
  }

  &.row__duo {
    display: flex;
    //background-color: fuchsia;

    @include respondto('res1280') {
      flex-direction: column;
    }

    @include respondto('res1280') {
      height: 1000px;
    }

    .row__duo__el {
      position: relative;
      width: 50%;
      height: 100%;
      transition: box-shadow .15s ease;

      @include respondto('res1280') {
        width: 100%;
      }

      .row__description {
        bottom: 100px;
      }

      &:hover {
        box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
      }

      &:nth-child(1) {
        background-image: url(/assets/images/pexels-photo-2584261.jpeg);
      }

      &:nth-child(2) {
        background-image: url(/assets/images/pexels-photo-2873015.jpeg);
      }

      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      .row__description {
        left: auto;
        right: 50px;
        padding-left: 50px;
        text-align: right;

        h1 {
          font-size: 11.5rem;
          line-height: 10.5rem;
          font-weight: 400;

          @include respondto('res1280') {
            font-size: 8rem;
            line-height: 8rem;
          }
        }

        h2 {
          font-size: 3.5rem;
        }

        a.btn {
          font-size: 2.5rem;
          margin-top: 1.5rem;
          font-size: 1.7rem;
          letter-spacing: 0.1rem;
          font-weight: 400;

        }
      }
    }
  }

  &.row__insta {
    position: relative;

    //background-color: fuchsia;

    text-align: center;
    //height: 215px;
    height: auto;

    .row__insta__title {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.7);
      color: #FFF;
      z-index: 1;
      margin: 0 auto;
      padding: 1rem 8rem;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 2rem;
      letter-spacing: 0.2rem;

      @include respondto('res768') {
        background: rgba(0, 0, 0, 0.95);
        font-size: 1.5rem;
        top: -25px;
      }


      span {
        color: #FEC135;

        @include respondto('res768') {
          display: inline-block;
        }
      }
    }

    .row__insta__container {

      width: 100%;
      display: flex;
      justify-content: space-around;
      line-height: 30px;

      @include respondto('res768') {
        display: block;
        font-size: 0rem;
      }


      .row__insta__el {

        position: relative;
        flex: 1 0 auto;
        height: auto;
        cursor: pointer;
        overflow: hidden;

        &:hover {

          img {
            opacity: 0.8;

          }

          span {
            transform: scale(1.1) rotate(2deg);
          }
        }

        img {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 18px;
          transition: opacity 0.3s ease;

          &.smaller {
            width: 14px;
            top: 13px;
            right: 13px;
          }
        }

        i {
          position: absolute;
          visibility: hidden;
        }

        .ig__image {

          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          padding-top: 100%;
          display: block;
          transition: all .3s ease;
          transform: scale(1.01);
          backface-visibility: hidden;

        }

        @include respondto('res768') {
          display: inline-block;
          width: 50%;
          margin-bottom: 0;
          vertical-align: top;

          &:last-of-type {
            //display: none;
          }
        }

        //&:before {
        //   content: '';
        //   float: left;
        //   padding-top: 100%;
        //}

        //&:after {
        //   content: '\A';
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        //   top: 0;
        //   left: 0;
        //   background: rgba(246, 36, 240, 0.2);
        //   opacity: 0;
        //   transition: all 0.3s;
        //}

        &:hover:after {
          opacity: 1;
        }

        &:nth-child(1) {
          background-image: url(/assets/images/pexels-photo-2887883.jpeg);
        }

        &:nth-child(2) {
          background-image: url(/assets/images/pexels-photo-783243.jpeg);
        }

        &:nth-child(3) {
          background-image: url(/assets/images/adult2-beautiful-blonde-1667761.jpg);
        }

        &:nth-child(4) {
          background-image: url(/assets/images/pexels-photo-2901918.jpeg);
        }

        &:nth-child(5) {
          background-image: url(/assets/images/AdobeStock_220433642.jpg);

          @include respondto('res990') {
            display: none;
          }
        }

        &:nth-child(6) {
          background-image: url(/assets/images/AdobeStock_278704001.jpg);

          @include respondto('res990') {
            display: none;
          }

        }

        &:nth-child(7) {
          background-image: url(/assets/images/audience-band-concert-167491.jpg);

          @include respondto('res768') {
            display: none;
          }

          @include respondto('res1280') {
            display: none;
          }
        }

        &:nth-child(8) {
          background-image: url(/assets/images/beautiful-blur-celebration-787968.jpg);

          @include respondto('res768') {
            display: none;
          }

          @include respondto('res1280') {
            display: none;
          }
        }

        &:nth-child(9) {
          background-image: url(/assets/images/dj-man-music-1649691.jpg);

          @include respondto('res768') {
            display: none;
          }
        }

        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);


        .row__description {
          left: auto;
          right: 85px;
          text-align: right;

          h1 {
            font-size: 10rem;
            font-weight: 400;
          }

          h2 {
            font-size: 3.5rem;
          }

          a.btn {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}


.row__mobile__container {
  display: none;
  background-color: #090909;
  padding: 0 8%;

  @include respondto('res768') {
    display: block;

  }

  .row__mobile {
    position: relative;
    background-image: url(/assets/images/pexels-photo-2887883.jpeg);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 250px;
    margin: 0 auto;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    text-transform: uppercase;

    .row__mobile__tags {
      position: absolute;
      top: 1rem;
      right: 1rem;

      span {
        display: inline-block;
        border: 1px solid #FFF;
        color: #FFF;
        font-size: 1rem;
        border-radius: 3px;
        padding: 3px 5px;
        margin-right: 4px;
      }
    }

    &.nobg {
      background-image: none;
      background-color: transparent;
      box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      height: 270px;
      //border:1px solid red;

      .row__mobile__titles {
        bottom: 6rem;

        h4 {
          font-size: 4.4rem;
          line-height: 4.4rem;
        }

        h5 {
          font-size: 2.1rem;
        }
      }
    }


    &.right {
      .row__mobile__titles {
        left: auto;
        right: 0;
        text-align: right;
        padding-left: 0;
        padding-right: 2rem;
      }

      .row__mobile__tags {

        right: auto;
        left: 1rem;
      }
    }

    .row__mobile__titles {
      position: absolute;
      bottom: 2rem;
      left: 0rem;
      padding-right: 0;
      padding-left: 2rem;

      h3 {
        text-transform: uppercase;
        color: #FFF;
        font-weight: 500;
        font-size: 3.8rem;
        line-height: 3.9rem;
      }

      h4 {
        color: #FEC135;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.7rem;
      }
    }

    &.alt-blog {
      width: 100%;
      margin-bottom: 101px;


      .row__mobile__titles {
        background: #090909;
        color: #FFF;
        width: 85%;
        top: calc(100% - 50px);
        padding: 2rem 0;
        bottom: auto;

        h3 {
          text-transform: none !important;
          font-size: 2.7rem;
          line-height: 2.8rem;
        }

        h4 {
          text-transform: none !important;
          font-size: 1.4rem;
          line-height: 3.5rem;
        }
      }

      &.right {
        .row__mobile__titles {
          left: auto;
          right: 0;
        }
      }

    }

    &.alt {

      width: 100%;
      margin-bottom: 101px;

      .row__mobile__titles {
        background: #FFF;
        padding-right: 0;
        padding: 1.7rem 4rem;

        bottom: -51px;
        left: -40px;

        h3 {
          color: #000;
          font-size: 3.7rem;
          line-height: 4.8rem;
        }

        h4 {
          color: #CD9C28;
          font-size: 1.9rem;
          line-height: 1.9rem;
          font-weight: 500;
        }

      }
    }

    &.alt.right {
      .row__mobile__titles {
        left: auto;
        right: -40px;
      }
    }

  }
}

.mtop60 {
  margin-top: 6rem !important;
}


#map {
  height: 400px;
}

#overlay {

  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  display: none;
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;

  padding: 5rem;

  @include respondto('res768') {
    padding: 5rem 8%;
  }

  @include respondto('above1280') {
    display: none !important;
  }

  .overlay__box {

    background: #FFF;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
    width: 100%;


    h3 {
      display: inline-block;
      font-size: 2.5rem;
      text-transform: uppercase;
      font-weight: 300;

      margin-top: 3rem;

      &:first-of-type {
        margin-top: 0;
      }
    }

    button {
      width: auto;
      display: inline-block;
      border: 0;
      padding: 1rem 2rem;
      font-size: 1.7rem;
      margin-top: 10px;
      cursor: pointer;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;

      &:first-of-type {
        margin-top: 20px;
      }

      &.done {
        background: #1ABC9C;
        color: #FFF;

        &:hover {
          background: #129279;
        }
      }

      &.cancel {
        background: #7F8C8D;
        color: #FFF;

        &:hover {
          background: #4C5555;
        }
      }
    }

    .input__text {
      input[type='text'] {
        text-align: center;
        padding: 5px 0;
        margin-top: 0.5rem;

        border: 2px solid #000;
        border-radius: 2px;
      }
    }

    .multiple__selects {

      margin-top: 0.8rem;
      font-size: 0;

      div {
        border: 2px solid #000;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 2px;
        cursor: pointer;
        margin-bottom: 0.8rem;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 0.8rem;

        &:hover,
        &[data-selected='1'] {
          background: #FEC135;
          color: #000;
          border-color: #FEC135;
        }
      }
    }
  }
}

.coupons__cointainer {
  display: flex;
  justify-content: center;
  margin-right: -6rem;
  flex-wrap: wrap;

  @include respondto('res600') {
    margin-right: 0;
  }
}

.events__hero .w--margin {
  display: block;
  color: #FFF;
  font-size: 2.3rem;
  line-height: 1.5;
  margin: 2rem 0;
  margin-top: 0;
}

.events__hero {
  .white__link {
    display: inline-block;
    margin-right: 0.8rem;
    background: #FFF;
    color: #000;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 2px;
    font-weight: 500;
    font-size: 1.4rem;
    transition: background-color 0.25s ease;
    margin-bottom: 1.1rem;

    &:hover {
      background-color: rgba(254, 193, 53, 1);
    }
  }
}


.read__more {
  box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.8);
  //background-image: url(/assets/images/AdobeStock_274617287.jpeg);
  padding: 5rem 0;
  display: flex;
  align-content: center;
  justify-content: center;

  a {
    display: inline-block;
    border: 2px solid #FFF;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0.8rem 1.4rem;
    color: #FFF;
    text-decoration: none;
    border-radius: 2px;
    transition: background 0.25s ease, color 0.25s ease;
    letter-spacing: 0.1rem;

    &:hover {
      background-color: #FFF;
      color: #333;
    }
  }
}