.btn {
  display: inline-block;
  padding: 10px;
  background: #CCC;
  color: #000;
  border-radius: 5px;
  text-decoration: none;
  border: 0;
  cursor: pointer;

  &.btn--big {
	font-size: 1.6rem;
	font-weight: 500;
	letter-spacing: 2px;
	padding: 1.5rem 4rem;
	text-transform: uppercase;
  }

  &.btn--extrapad {
	padding: 1.3rem 3.5rem;
  }

  &.btn--white {
	background: #FFF;
  }

  &.btn--disabled {
	background: rgba(152, 152, 152, 0.5);
	color: #FFF;
	cursor: not-allowed;

	transition: 0.3s ease;

	&:hover {
	  filter: brightness(1.15);
	}
  }

  &.btn--blue {
	background: #00519D;
	color: #FFF;
	transition: 0.3s ease;

	&:hover {
	  filter: brightness(1.15);
	}
  }
}

.btn-upload {
  position: relative;
  background: #FFF;
  color: #333;
  padding: 0.8rem 1rem;
  border: 0;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.2s ease;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.7rem;
  min-height: 45px;
  //transition: color 0.2s ease;

  .fas {
	position: absolute;
	right: 0px;
	top: 0px;
  }


  &:hover {
	background: #E2E2E2;
  }

  &[data-uploaded='1'] {
	background: #27AE60;
	color: #FFF;
	text-transform: none;
  }
}