.choices__inner {
  background-color: transparent !important;
}

.choices__item {
  text-transform: none !important;
}

.choices__list--dropdown, .choices__input {
  color: #333 !important;
  text-transform: none !important;
  font-weight:bold !important;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: #fec135 !important;
}