
.blog__article__description__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  color: #FFF;


  a {
	font-size: 3rem;
	font-weight: 500;
	text-decoration: none;
	color: inherit;
	display: block;
	margin-bottom: 0.6rem;
  }

  .title__description {
	display: block;
	color: #FEC135;
	font-size: 1.7rem;
  }

  .article__description {
	display: block;
	color: #FFF;

	font-size: 1.4rem;
	margin: 1rem 0;

	font-style: italic;
	line-height: 2rem;
  }


}

.row__penta__big {
  .blog__article__description__bottom {

	a {
	  font-size: 4rem;

	  @include respondto('res1280') {
		font-size: 3rem;
	  }

	}

	.title__description {
	  font-size: 2.1rem;

	  @include respondto('res1280') {
		font-size: 1.7rem;
	  }
	}

	.article__description {
	  font-size: 1.7rem;
	  margin: 2rem 0;
	  line-height: 2.5rem;

	  @include respondto('res1280') {
		font-size: 1.4rem;
	  }
	}
  }
}

.blog__article__tags {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;

  .blog__article__tag {
	display: inline-block;
	border: 2px solid #FEC135;
	padding: 0.4rem 0.8rem;
	color: #FEC135;
	margin-right: 1rem;
	font-weight: 500;
	transition: background 0.25s ease, color 0.25s ease;
	cursor: pointer;
	border-radius: 2px;
	font-size: 1.2rem;
	letter-spacing: 0.1rem;

	&:hover {
	  background: #FEC135;
	  color: #111;
	}
  }
}
