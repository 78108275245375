footer {
   position: relative;
   display: flex;
   background-color: rgb(1, 14, 31);
   color: #FFF;
   padding: 0 6rem;
   flex-wrap: wrap;

   @include respondto('res768') {
	  padding-left: 8%;
	  padding-right: 8%;
   }

   @include respondto('res990') {

	  display: block;
	  //font-size: 0rem;
	  padding-top: 280px;
   }

   .footer__squared__img {
	  position: relative;
	  width: 100%;
	  height: 280px;
	  background-image: url(/assets/images/pexels-photo-862848.jpeg);
	  background-size: cover;
	  margin: 0 auto;

	  @include respondto('res990') {
		 position: absolute;
		 top: 0px;
		 left: 0px;
		 width: 100%;
	  }

	  .text {
		 position: absolute;
		 bottom: 1.7rem;
		 right: 0;
		 padding: 0 2.1rem;
		 width: 100%;
		 text-align: right;

		 text-transform: uppercase;

		 @include respondto('res990') {
			background-color: #010E1F;
			width: 258px;

			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			text-align: center;

			padding: 2rem 0;
			bottom: -51px;
		 }

		 strong {
			font-weight: 500;
			font-size: 2.7rem;
			letter-spacing: -0.1rem;

		 }

		 span {
			display: block;
			color: #FEC135;
			//font-weight: bold;
			font-size: 1.3rem;
		 }
	  }
   }

   .footer__column {
	  width: 25%;
	  font-size: 1.3rem;
	  padding: 6rem 3rem;
	  padding-bottom: 3rem;

	  @include respondto('res1280') {
		 padding-left: 2.9rem;
		 padding-right: 2.9rem;
	  }

	  &.alt {
		 padding-top: 0;
		 padding-bottom: 6rem;

		 @include respondto('res990') {

			padding-bottom: 0rem;

			&:first-of-type {
			   padding-top: 2rem;
			   padding-bottom: 6rem;
			}

			&:last-of-type {
			   //border:1px solid red;
			   padding-bottom: 6rem;
			}

			b {
			   display: none;
			}
		 }
	  }

	  @include respondto('res990') {

		 width: 100%;
		 padding: 0;
		 text-align: center;
		 padding-top: 2.8rem;
		 padding-bottom: 2.8rem;

	  }


	  &--flex {
		 display: flex;
		 flex-direction: column;
		 align-items: flex-start;

		 @include respondto('res990') {
			display: block;
		 }
	  }

	  .footer__column__title {
		 display: block;
		 text-transform: uppercase;
		 font-weight: 600;
		 margin-bottom: 2rem;
		 margin-top: 2rem;


		 &:first-of-type {
			margin-top: 0;
		 }
	  }

	  .footer__column__title, .footer__column__link {
		 letter-spacing: 0.2rem;
	  }

	  .footer__column__link {
		 text-transform: uppercase;
		 text-decoration: none;
		 color: inherit;
		 display: block;
		 margin-bottom: 0.8rem;
		 transition: color 0.25s ease;

		 @include respondto('res990') {
			display: inline-block;
			//border:1px solid red;
			font-size: 1.5rem;
		 }

		 &:hover {
			color: #FEC135;
		 }
	  }

	  & > .icon {
		 display: inline-block;
		 margin-right: 1.5rem;
		 font-size: 1.6rem;

		 i {
			position: absolute;
			visibility: hidden;
		 }

		 &:last-of-type {
			margin-right: 0;
		 }

		 &.icon-facebook:hover {
			color: #3B5998;
		 }

		 &.icon-twitter:hover {
			color: #1DA1F2;
		 }

		 &.icon-instagram:hover {
			color: #E1306C;
		 }
	  }

	  .highlighted {
		 display: inline-block;
		 background: #FEC135;
		 color: #000;
		 text-decoration: none;
		 border-radius: 2px;
		 padding: 0.7rem 1.4rem;
		 text-transform: uppercase;
		 font-size: 1.2rem;
		 font-weight: 600;
		 letter-spacing: 0.1rem;

		 @include respondto('res990') {
			margin-bottom: 2rem;
		 }

		 &:hover {
			filter: brightness(1.1);
		 }
	  }

	  .tag {


		 display: inline-block;
		 margin-right: 0.4rem;
		 border: 2px solid #FFF;
		 padding: 0.6rem 1rem;
		 border-radius: 2px;
		 cursor: pointer;
		 letter-spacing: 0.1rem;
		 font-weight: 500;
		 line-height: 1.3rem;
		 text-align: left;
		 color: inherit;
		 text-decoration: none;

		 @include respondto('res990') {
			width: auto !important;
		 }

		 @include respondto('res1280') {
			font-size: 1.2rem;
		 }

		 &.plain {
			transition: color 0.25s ease, border-color 0.25s ease;
			margin-bottom: 0.8rem;
			font-size: 1rem;
			font-weight: 400;
			border: 1px solid #FFF;
			padding: 0.4rem 0.8rem;
			line-height: 1rem;

			@include respondto('res990') {
			   display: inline-block;
			   width: auto;
			}

			&:hover {
			   color: #FEC135;
			   border-color: #FEC135;
			}
		 }


		 .icon {
			margin-right: 0.3rem;
			font-size: 1.2rem;

			@include respondto('res1280') {
			   display: none;
			}
		 }

		 &.whatsapp {
			border-color: #25D366;
			margin-bottom: 1rem;

			@include respondto('res1280') {
			   width: 100%;
			   //padding: 0.6rem 0;
			}

			.icon {
			   color: #25D366;
			}
		 }

		 &.telegram {
			border-color: #08C;
			margin-bottom: 1rem;

			@include respondto('res1280') {
			   width: 100%;
			   //padding: 0.6rem 0;
			}

			.icon {
			   color: #08C;
			}
		 }

		 &.email {
			border-color: #FEC135;

			@include respondto('res1280') {
			   width: 100%;
			   //padding: 0.6rem 0;
			}

			.icon {
			   color: #FEC135;
			}
		 }
	  }
   }
}

.footer__end {
   background: #001228;
   color: #FFF;

   text-align: center;
   padding: 3.5rem 5rem;
   font-size: 0;

   @include respondto('res768') {
	  padding-left: 8%;
	  padding-right: 8%;
   }


   .footer__end__logo {
	  display: inline-block;
	  width: 160px;
	  height: 30px;
	  background: transparent;
	  vertical-align: top;

	  background-image: url(/assets/images/logo-nightcode-bianco.png);
	  background-size: contain;
	  background-repeat: no-repeat;
   }

   .footer__end__text {
	  display: inline-block;
	  vertical-align: top;
	  text-align: left;
	  border-left: 1px solid #FEC135;
	  padding-left: 1.5rem;
	  margin-left: 1.5rem;
	  font-size: 1.3rem;
	  line-height: 1.8rem;
	  font-weight: 400;
	  padding-bottom: 0.7rem;

	  @include respondto('res990') {
		 padding: 0;
		 text-align: center;
		 border: 0;
	  }

	  b {
		 font-weight: 600;
	  }
   }
}

.hidden__dash {
   display: none;

   @include respondto('res990') {
	  display: inline-block;
   }
}