.organize {
   .organize__hero {
	  position: relative;
	  width: 100%;

	  background-size: cover;
	  background-position: right top;
	  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.65);
	  background-color: #00172B;
	  background-image: url(/assets/images/AdobeStock_274617287long2.jpg);
	  background-repeat: no-repeat;
	  text-transform: uppercase;

	  padding: 5.0rem;
	  padding-bottom: 3.9rem;

	  @include respondto('res768') {
		 padding-top: 0;
		 padding-left: 8%;
		 padding-right: 8%;
	  }

	  &.no-padding {
		 padding-left: 0;
		 padding-right: 0;
		 padding-bottom: 11rem;

		 h1, h2, span, .form {
			display: block;
			padding-left: 5rem;
			padding-right: 3.9rem;

		 }
	  }

	  .organize__hero__title {
		 color: #FFF;
		 text-align: right;
		 //margin-top: 485px;
		 margin-top: 51vh;

		 @include respondto('res768') {
			margin-top: 0;
			padding-top: 28rem;
		 }

		 h1 {
			font-size: 10rem;
			font-weight: normal;
			line-height: 9rem;
			margin-bottom: 1rem;

			@include respondto('res768') {
			   font-size: 6rem;
			   line-height: 5rem;

			}

			@include respondto('res600') {
			   font-size: 4.5rem;
			   line-height: 4.8rem;
			}
		 }

		 h2 {
			font-size: 3.4rem;
			color: #FEC135;
			font-weight: normal;

			@include respondto('res600') {
			   font-size: 2.5rem;
			}
		 }

		 span {
			font-size: 2.3rem;
			text-transform: none;

			@include respondto('res768') {
			   font-size: 1.8rem;
			}
		 }
	  }

	  .event__description {
		 position: absolute;
		 bottom: 85px;
		 left: 85px;

		 .event__description__details {
			margin-top: 2.0rem;

			.event__texts {
			   display: inline-block;
			   vertical-align: top;
			   font-size: 1.8rem;
			   margin-left: 2rem;

			   h3 {
				  color: #FFF;
				  font-weight: 300;
				  margin-bottom: 1.0rem;
			   }

			   strong {
				  display: block;
				  color: #FEC135;
				  font-size: 2.5rem;
				  font-weight: 600;

				  &.alt {
					 font-size: 2.1rem;
					 color: #FFF;
				  }
			   }

			   .event__texts__tags {
				  display: inline-block;
				  font-size: 1.5rem;
				  margin-top: 1.5rem;
				  color: #FFF;
				  margin-right: 5.0rem;

				  &.tags--yellow {
					 .tag {
						background-color: #FEC135;
					 }
				  }

				  &.tags--transparent {
					 .tag {
						background-color: transparent;
						border: 2px solid #FEC135;
						color: #FEC135;
					 }
				  }

				  .tag {
					 display: inline-block;
					 padding: 0.4rem 1rem;
					 background-color: #FFF;
					 border-radius: 0.4rem;
					 color: #000;
					 margin-top: 0.5rem;
					 font-weight: 500;
				  }
			   }
			}

			.event__day {
			   display: inline-block;
			   vertical-align: top;

			   background: #FFF;
			   color: #000;
			   border-radius: 10px;
			   padding: 1.0rem 2.5rem;
			   text-align: center;

			   font-weight: 400;

			   span,
			   strong {
				  display: block;
			   }

			   span {
				  font-size: 2.0rem;
			   }

			   strong {
				  font-size: 6.0rem;
				  font-weight: 800;
			   }
			}
		 }

		 h1,
		 h2 {
			text-transform: uppercase;
			font-weight: 500;
		 }

		 h1 {
			color: #FFF;
			font-size: 11rem;
		 }

		 h2 {
			color: #FEC135;
			font-weight: 300;
			font-size: 4.0rem;
		 }

		 a.btn {
			text-transform: uppercase;
			font-size: 2.5rem;
			margin-top: 1.5rem;
		 }
	  }
   }

   .big__threee__squares {

	  display: flex;
	  flex-wrap: wrap;
	  background-color: transparent;
	  box-shadow: none;

	  .big__square {

		 position: relative;
		 width: 33.3%;
		 background-size: cover;
		 background-position: center center;
		 box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
		 transition: box-shadow 0.15s ease;
		 cursor: pointer;

		 @include respondto('res1280') {
			width: 50%;

		 }

		 @include respondto('res990') {
			width: 100%;

		 }

		 &:hover {
			box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
		 }

		 span {
			position: absolute;
			bottom: 1.5rem;
			left: 3rem;
			color: #FFF;
			font-size: 2.5rem;
			//line-height: 4.5rem;
			text-transform: lowercase;


			@include respondto('res1280') {
			   font-size: 1.8rem;
			}

			@include respondto('res600') {
			   font-size: 1.4rem;
			}

			strong {
			   display: block;
			   color: #FEC135;
			   font-size: 7rem;
			   text-transform: uppercase;

			   @include respondto('res1280') {
				  font-size: 4.5rem;
			   }

			   @include respondto('res600') {
				  font-size: 3rem;
			   }
			}
		 }

		 &:nth-child(1) {
			background-image: url(/assets/images/AdobeStock_221730656.jpg);
		 }

		 &:nth-child(2) {
			background-image: url(/assets/images/AdobeStock_220433642.jpg);
		 }

		 &:nth-child(3) {
			background-image: url(/assets/images/AdobeStock_278704001.jpg);
		 }

		 &:after {
			content: "";
			display: block;
			padding-bottom: 75%;

			@include respondto('res1280') {
			   padding-bottom: 45%;
			}

			@include respondto('res990') {
			   padding-bottom: 35%;
			}

			@include respondto('res600') {
			   padding-bottom: 55%;
			}
		 }
	  }
   }
}
