@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/font/icomoon.eot?waffnx');
  src:  url('/assets/font/icomoon.eot?waffnx#iefix') format('embedded-opentype'),
  url('/assets/font/icomoon.ttf?waffnx') format('truetype'),
  url('/assets/font/icomoon.woff?waffnx') format('woff'),
  url('/assets/font/icomoon.svg?waffnx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-pinterest:before {
  content: "\e903";
}
.icon-telegram:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e905";
}
.icon-whatsapp:before {
  content: "\e906";
}
.icon-envelope:before {
  content: "\e907";
}
