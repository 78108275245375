.menu__overlay__logo {
   background-image: url(/assets/images/logo-nightcode-bianco.png);
   width: 200px;
   height: 35px;
   background-size: contain;
   background-repeat: no-repeat;
   position: absolute;
   top: 4rem;
   left: 5rem;
   z-index: 999;
   cursor: pointer;

   @include respondto('res768') {
	  left: 8%;
   }
}

#menu__overlay {
   display: none;
   position: fixed;
   width: 100%;
   height: 100vh;
   background-color: rgba(0, 20, 41, 0.98);
   z-index: 99;


   .menu__separator {
	  width: 90px;
	  height: 2px;
	  background-color: #FEC135;

	  margin: 0 auto;
	  margin-top: 4rem;
	  margin-bottom: 4rem;
   }

   .menu__socials {


	  .icon {
		 color: #FFF;
		 font-size: 2.5rem;
		 margin-right: 2rem;
		 cursor: pointer;
		 transition: color 0.25s ease;
		 text-decoration: none;


		 i {
			position: absolute;
			visibility: hidden;
		 }

		 &:last-of-type {
			margin-right: 0;
		 }

		 &:hover {
			color: #FEC135;
		 }
	  }
   }

   .menu__links {

	  margin: 0;
	  position: absolute;
	  top: 12.5rem;
	  left: 50%;
	  -ms-transform: translate(-50%, 0%);
	  transform: translate(-50%, 0%);

	  text-align: center;
	  padding: 0 5rem;
	  padding-bottom: 2.5rem;
	  width: 100%;
	  overflow-y: auto;
	  height: calc(100vh - 12.5rem);


	  //border: 1px solid green;

	  .menu__link {
		 //border: 1px solid red;
		 margin-bottom: 3rem;

		 a {
			color: #FFF;
			text-transform: uppercase;
			font-size: 2.3rem;
			letter-spacing: 0.4rem;
			text-decoration: none;
			font-weight: 500;
			transition: color 0.25s ease;
			margin-bottom: 0.3rem;
			display: block;

			&:hover {
			   color: #FEC135;
			}
		 }

		 >
		 span {
			display: block;
			text-transform: uppercase;
			color: #FEC135;
			font-size: 1.2rem;
			letter-spacing: 0.2rem;
		 }
	  }
   }
}


.hamburger__menu {
   display: none;
   position: fixed;
   right: 37px;
   top: 20px;
   z-index: 999;
   cursor: pointer;
   background: rgba(0, 0, 0, 0.35);
   padding: 2rem 1.3rem;
   border-radius: 50%;
   transition: background 0.65s ease;


   .hamburger__menu__line {
	  width: 43px;
	  height: 2px;
	  background: #FFF;
	  margin-bottom: 1.1rem;
	  border-radius: 2px;


	  &:last-of-type {
		 margin-bottom: 0;
	  }
   }

}


.background-box {
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: rgba(255, 0, 0, 0.5);
   transition: 1s ease;
   z-index: 999;
}

.background-box.open {
   background-color: #08081F;
}

.mobile-menu {
   width: 43px;
   height: 30px;
   position: fixed;
   right: 50px;
   top: 39px;
   cursor: pointer;
   z-index: 99999;

   @include respondto('res768') {
	  right: 8%;
   }

   &.opening {
	  #hamburger-bg {
		 background-color: transparent;
	  }
   }
}

#hamburger-bg {
   width: 70px;
   height: 70px;

   border-radius: 50%;
   margin-top: -20px;
   margin-left: -14px;

   transition: 0.6s ease;

   &.w--bg {
	  background-color: rgba(0, 20, 41, 0.98);
   }
}

.mobile-menu > .line {
   height: 2px;
   background-color: #FFF;
   width: 43px;
   position: absolute;
   left: 0;
   border-radius: 5px;
   transition: transform 0.35s ease;
}

.mobile-menu > .line.line-1 {
   top: 0;
   transform: rotate(0);
   transform-origin: top left;
}

.mobile-menu > .line.line-2 {
   top: 14px;
   left: 0;
   transition: transform 0.3s ease, left 0.4s ease 0.15s;
}

.mobile-menu > .line.line-3 {
   top: 28px;
   transform: rotate(0);
   transform-origin: bottom left;
}

.mobile-menu.opening > .line {
   opacity: 1;
   transition: opacity 0s ease, transform 0s ease, left 0s ease, top 0s ease;
}

.mobile-menu.opening > .line.line-1 {
   transform: rotate(45deg);
   left: -200%;
   top: -300%;
}

.mobile-menu.opening > .line.line-2 {
   left: 300%;
}

.mobile-menu.opening > .line.line-3 {
   transform: rotate(-45deg);
   left: 200%;
   top: -300%;
}

.mobile-menu.open > .line {
   opacity: 1;
   transition: left 0.3s ease, top 0.3s ease;
}

.mobile-menu.open > .line.line-1 {
   left: 0;
   top: 0;
}

.mobile-menu.open > .line.line-2 {
   opacity: 0;
}

.mobile-menu.open > .line.line-3 {
   left: 0;
   top: 29px;
   transition: left 0.3s ease 0.3s, top 0.3s ease 0.3s;
}

body.modal-open {
   overflow: hidden;
}