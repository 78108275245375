* {
  outline:0;
  -webkit-tap-highlight-color: transparent!important;
}

html {
	font-size: 62.5%;
}

body {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: normal;

	font-size:1.6rem;


}



strong, b {
	font-weight: 800;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@import "vars/colors";

@import "helpers/normalize";
@import "layout/responsive";
@import "layout/sidemenu";
@import "layout/main";

@import "components/menu";
@import "components/titles";
@import "components/buttons";
@import "components/titles";
@import "components/tab";
@import "components/footer";
@import "components/overrides";
@import "components/_nouislider.css";
@import "components/choices";

@import "font/icomoon";


@import "pages/blog";
@import "pages/blog_article";
@import "pages/event";
//@import "pages/local";
@import "pages/organize";
@import "pages/events";
@import "pages/search";

