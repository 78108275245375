.tabs {
   background: #001326;
   display: flex;
   //align-items: center;
   flex-wrap: wrap;


   .tab {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  text-align: center;
	  flex: 1;
	  color: #FFF;
	  text-transform: uppercase;
	  font-weight: 600;
	  letter-spacing: 2px;
	  padding: 2rem 3rem;
	  cursor: pointer;
	  font-size: 1.7rem;
	  line-height: 2.2rem;

	  .fakeButton {
		 display: none;
		 font-size: 0.9rem;
		 font-weight: 500;
		 background-color: #FEC135;
		 color: #000;
		 padding: 0.5rem 0.7rem;
		 line-height: 0.9rem;
		 border-radius: 2px;
		 letter-spacing: 0.1rem;
	  }

	  @include respondto('res768') {
		 flex: 0 0 100%;
		 text-align: left;
		 justify-content: space-between;
		 padding: 2rem 8%;
		 font-size: 1.5rem;
		 line-height: 1.9rem;

		 .fakeButton {
			display: block;
		 }

	  }

	  @include respondto('res600') {
		 font-size: 1.4rem;
		 line-height: 1.8rem;
		 //padding: 2rem 5rem;
	  }

	  &:hover,
	  &[data-current='1'] {
		 background-color: #0054A6;
	  }
   }
}

.tabs__content {
   position: relative;
   background: #110F10;
   color: #FFF;
   padding: 4.0rem 5.0rem;
   display: none;
   //border:2px solid #1B1C1A;

   &.calendar {
	  .choices {
		 width: 350px;

		 @include respondto('res600') {
			width: 100%;
		 }
	  }
   }

   @include respondto('res768') {
	  padding: 4rem 8%;
   }

   &.tab__no__padding {
	  padding: 0;
   }

   &.fakepadding {
	  padding: 0;

	  .generic__content {
		 padding: 4rem 6rem;
	  }
   }

   h2.yellow-title {
	  text-transform: uppercase;
	  font-size: 4.2rem;
	  font-weight: normal;
	  color: #FEC135;
	  margin-bottom: 3rem;

	  &.alt-padding {
		 margin-bottom: 0;
	  }
   }

   .generic__content.alt {
	  h3 {
		 color: #FEC135;
	  }

	  h4 {
		 color: #FFF;
	  }
   }


   .generic__content {
	  display: flex;
	  margin-top: 50px;

	  @include respondto('res990') {
		 flex-direction: column;
	  }

	  &.fullw {
		 display: block;
		 width: 100%;

		 @include respondto('res990') {
			display: flex;
		 }

	  }

	  &:first-of-type {
		 margin-top: 0;
	  }

	  .image {
		 position: relative;
		 width: 250px;
		 height: 250px;
		 //
		 background: url(/assets/images/pexels-photo-2240771.jpeg);
		 box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

		 background-position: center center;
		 background-repeat: no-repeat;
		 background-size: cover;
		 margin-right: 30px;
		 cursor: pointer;
		 transition: box-shadow .15s ease;

		 @include respondto('res600') {
			width: 100%;
		 }

		 @include respondto('res990') {
			margin-bottom: 2.5rem;
		 }

		 &:hover {
			box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
		 }

		 h4 {
			position: absolute;
			bottom: 1.6rem;
			left: 1rem;
			font-size: 1.7rem;
			line-height: 2.5rem;
			font-weight: 300;

			@include respondto('res600') {
			   left: 2.5rem;
			   bottom: 2.5rem;
			}

			strong {
			   display: block;
			   color: #FFF;
			   text-transform: uppercase;
			   font-size: 4rem;
			   font-weight: normal;
			}
		 }
	  }


	  .text {
		 flex: 1;

		 h3 {
			text-transform: uppercase;
			font-size: 3.3rem;
			font-weight: normal;
		 }

		 h4 {
			font-weight: normal;
			margin-bottom: 2rem;
			font-weight: 500;
		 }


		 p {
			font-weight: 500;
			font-size: 1.8rem;
			color: #FFF;
			margin-top: 0.2rem;
		 }
	  }

	  .square__img {
		 position: relative;
		 display: inline-block;
		 vertical-align: top;
		 width: 250px;
		 height: 250px;
		 margin-right: 30px;


		 background: url(/assets/images/pexels-photo-2240771.jpeg);
		 box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

		 background-position: center center;
		 background-repeat: no-repeat;
		 background-size: cover;

		 @include respondto('res600') {
			width: 100%;

		 }

		 @include respondto('res990') {
			margin-bottom: 2.5rem;
		 }

		 &:before {
			content: '';
			float: left;
			padding-top: 100%;
		 }

		 &:after {
			content: '\A';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(254, 193, 53, 0.1);
			opacity: 0;
			transition: all 0.3s;
			cursor: pointer;
		 }

		 &:hover:after {
			opacity: 1;
		 }

		 &:nth-child(1) {
			background-image: url(/assets/images/pexels-photo-2629336.jpeg);
		 }

		 &:nth-child(2) {
			background-image: url(/assets/images/adult-black-shirt-cap-1267341.jpg);
		 }

		 &:nth-child(3) {
			background-image: url(/assets/images/adult-begging-boots-256621.jpg);
		 }

		 span {
			position: absolute;
			bottom: 1.5rem;
			right: 1.5rem;
			font-size: 3rem;
			text-transform: uppercase;
			text-align: right;
			color: #FFF;
			font-weight: 500;
			line-height: 3rem;

			@include respondto('res600') {
			   right: 3.5rem;
			   bottom: 2.5rem;
			}

			strong {
			   display: block;
			   color: #FEC135;
			   font-weight: 500;
			}
		 }
	  }

	  select {
		 background-color: transparent;
		 border: 1px solid #FFF;
		 padding: 1rem;
		 color: #FFF;
		 min-width: 300px;
	  }
   }

   .six__squares {
	  display: flex;

	  & > div {
		 position: relative;
		 width: calc(100% / 6);
		 height: 265px;
		 background: pink;

		 background: url(/assets/images/pexels-photo-2240771.jpeg);
		 box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);

		 background-position: center center;
		 background-repeat: no-repeat;
		 background-size: cover;
		 transition: box-shadow .15s ease;
		 cursor: pointer;

		 &:hover {
			box-shadow: inset 0 0 0 2000px rgba(13, 11, 35, 0.3);
		 }

		 &:nth-child(1) {
			background-image: url(/assets/images/artist-guitar-guitarist-33597.jpg);
		 }

		 &:nth-child(2) {
			background-image: url(/assets/images/artists-audience-band-1763075.jpg);
		 }

		 &:nth-child(3) {
			background-image: url(/assets/images/analogue-antique-audio-290657.jpg);
		 }

		 &:nth-child(4) {
			background-image: url(/assets/images/black-and-white-concert-festival-210922.jpg);
		 }

		 &:nth-child(5) {
			background-image: url(/assets/images/adult-buildings-city-1280468.jpg);
		 }

		 &:nth-child(6) {
			background-image: url(/assets/images/pexels-photo-1449791.jpeg);
		 }

		 .tl {
			position: absolute;
			left: 16px;
			top: 14px;
			color: #C2DDF0;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 1.1rem;
			letter-spacing: 0.1rem;
			border: 2px solid;
			padding: 0.7rem;
			border-radius: 0.3rem;
			opacity: 0.8;
		 }

		 .br {
			position: absolute;
			right: 2rem;
			bottom: 2rem;
			text-align: right;

			h4 {
			   color: #FFF;
			   font-weight: normal;

			   font-size: 3.1rem;
			   line-height: 3.1rem;
			   font-weight: 400;
			   text-transform: uppercase;

			}

			h5 {
			   color: #FEC135;
			   font-weight: 400;
			   font-size: 2rem;
			   text-transform: uppercase;
			}

			a {
			   font-size: 1.3rem;
			   margin-top: 1.2rem;
			   padding: 0.8rem 1.8rem;
			   text-transform: uppercase;
			   transition: background-color 0.15s ease;
			   opacity: 0.8;

			   &:hover {
				  background-color: rgba(254, 193, 53, 1);
				  opacity: 1;
			   }
			}
		 }
	  }
   }

   &.no-padding {
	  padding: 0;
   }

   &[data-current='1'] {
	  display: block;
   }

   iframe {
	  width: 99.99%;
	  height: 400px;
	  margin-bottom: -4px;
   }

   .tabs__inputs {
	  margin-bottom: 25px;
	  margin-top: 30px;

	  &:first-of-type {
		 margin-top: 0;
	  }

	  &:last-of-type {
		 margin-bottom: 0;
	  }

	  b {
		 color: #FEC135;
		 display: block;
		 margin-bottom: 0.9rem;
		 font-size: 2.5rem;
		 font-weight: 500;
	  }

	  label {
		 position: relative;
		 display: inline-block;
		 width: 24%;
		 padding: 15px;
		 font-size: 2rem;

		 span {
			display: block;
			text-transform: uppercase;
			margin-bottom: 0.8rem;
		 }

		 input {
			width: 100%;
			border: 0;
			border-bottom: 2px solid #999;
			background: transparent;
			color: #FFF;
			font-weight: bold;
			padding: 4px 0;
			outline: 0;
			letter-spacing: 2px;

		 }

		 select {
			width: 100%;
			background: transparent;
			color: #FFF;
			font-weight: bold;
			padding: 4px 0;
			letter-spacing: 2px;
			//border: 1px solid #CCC;
			border: 0;
			border-bottom: 2px solid #999;
		 }

		 textarea {
			width: 100%;
			border: 2px solid #999;
			background: transparent;
			font-weight: normal;
			padding: 10px;
			color: #FFF;
			resize: vertical;
			margin-top: 5px;
			min-height: 250px;
		 }
	  }
   }

   h4 {
	  color: #FEC135;
	  text-transform: none;
	  font-size: 22px;
   }

   p {
	  display: block;
	  margin-bottom: 15px;
	  line-height: 25px;
	  color: #CACACE;

	  b {
		 color: #FFF;

		 &.pink {
			color: #FEC135;
		 }
	  }
   }
}

.eula {
   margin-top: 40px;
   //text-align: center;
   color: #FFF;
   padding-right: 5rem;
   //cursor:pointer;

   @include respondto('res768') {
	  padding-right: 0;
   }


   .eula__agreement {
	  display: flex;
	  justify-content: flex-start;
	  align-items: flex-start;
   }

   img {
	  //filter: invert(1);
	  display: inline-block;
	  vertical-align: middle;
	  max-width: 21px;
	  margin-right: 9px;

	  cursor: pointer;
   }

   span {
	  display: inline-block;
	  vertical-align: middle;
	  text-transform: none;
	  font-size: 1.8rem;
	  line-height: 2.1rem;
   }

   a {
	  color: inherit;
   }

   i {
	  margin-right: 5px;
	  color: #FEC135;
   }

   button {
	  display: block;

	  //margin: 0 auto;
	  margin-top: 30px;

	  @include respondto('res600') {
		 width: 100%;
	  }
   }
}
